<template>
  <div class="pageV">
    <div class="header">
      <van-nav-bar
        :title="$t('chongzhi')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>

    <div class="centV">
      <div class="topV">
        <span style="color: rgba(255, 255, 255, 0.85); font-size: 1rem;">
          {{ $t("AccountBalance") }}
        </span>
        <van-row>
          <span style="color: #fff; font-size: 2rem;">{{ $t("fuhao") }}</span>
          <span style="color: #fff; font-size: 2rem;margin-left: 0.5rem;">
            {{ balance }}
          </span>
        </van-row>
      </div>

      <div class="cententV">
        <span class="inputTitle">{{ $t("RechargeAmount") }}</span>
        <div class="inputV">
          <span style="color: #333; font-size: 1rem;">{{ $t("fuhao") }}</span>
          <van-field
            :placeholder="$t('PleaseAmount')"
            type="number"
            v-model="money"
            @input="onInputUd"
            clearable
          ></van-field>
        </div>
        <van-grid :column-num="4" :border="false">
          <van-grid-item v-for="(item, index) in moneyList" :key="index">
            <span
              @click="onItemMoneyClick(item, index)"
              :class="moneyPos == index ? 'moneyItemTo' : 'moneyItem'"
            >
              {{ $t("fuhao") }}{{ item }}
            </span>
          </van-grid-item>
        </van-grid>
      </div>

      <span style="height: 0.62rem; background-color: #f7f7f7;"></span>
      <span class="cardV">{{ $t("PaymentMethod") }}</span>

      <div
        @click="onItemPayClick(payItem, payIndex)"
        class="cardItem"
        v-for="(payItem, payIndex) in thirdList"
        :key="payIndex"
      >
        <img
          style="border-radius: 50%; width: 17px;height: 17px;margin-right: 3px;"
          :src="payItem.logo"
        />
        <span> {{ payItem.name }}</span>
        <img v-if="payPos == payIndex" src="../../assets/img/selete_.png" />
        <img v-else src="../../assets/img/selete.png" />
      </div>

      <div style="margin-left: 1rem; margin-right: 1rem;">
        <van-button
          class="confirmTv"
          :loading="loading"
          :loading-text="$t('jia-zai-zhong')"
          @click="submit"
        >
          {{ $t("Confirm") }}
        </van-button>
      </div>

      <span class="cardV">{{ $t("RechargeInstructions") }}</span>

      <div class="htmlTv">
        <p v-html="txtHtml"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      money: "",
      txtHtml: "",
      balance: "",
      moneyList: [1000, 2000, 3000, 5000, 10000, 20000, 50000, 100000],
      moneyPos: -1,
      payPos: -1,
      thirdList: [],
      loading: false,
    };
  },
  created() {
    this.getPayTxt();
    this.getinfo();
    this.getPayThird();
  },
  methods: {
    //获取余额
    async getinfo() {
      const { data } = await this.$http.get("/home/user/withdraw");
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance;
        }
      }
    },
    //获取充值说明
    async getPayTxt() {
      const { data } = await this.$http.get("/home/index/payText?id=1");
      if (data) {
        if (data.code === 200) {
          this.txtHtml = data.data;
        }
      }
    },
    //获取充值通道
    async getPayThird() {
      const { data } = await this.$http.get("/home/pay/payThird");
      if (data) {
        if (data.code === 200) {
          this.thirdList = data.data;
        }
      }
    },
    onItemMoneyClick(item, index) {
      this.moneyPos = index;
      this.money = item;
    },
    onInputUd(e) {
      let pos = -1;
      this.moneyList.map((item, index) => {
        if (e == item) {
          pos = index;
        }
      });
      this.moneyPos = pos;
    },
    onItemPayClick(item, index) {
      this.payPos = index;
    },
    async submit() {
      if (this.money.length == 0) {
        this.$toast.fail(this.$t("PleaseAmount"));
        return false;
      }
      if (this.payPos == -1) {
        this.$toast.fail(this.$t("PleaseSelectaRechargeChannel"));
        return false;
      }
      this.loading = true;
      let postform = {
        price: this.money,
        third_id: this.thirdList[this.payPos].id,
      };
      const { data } = await this.$http.post("/home/pay/pay", postform);
      if (data) {
        if (data.code === 200) {
          
          if(data.data==1){
            this.$toast.success(this.$t("czhi.submitok"));
          }else{
            window.location.href = data.data;
          }
          
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.pageV {
  display: flex;
  flex-direction: column;
}

.header {
  box-shadow: none;
}
.centV {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  background: #fff;

  .topV {
    width: 100%;
    height: 6.625rem;
    background-image: url("../../assets/img/top_bg.png");
    background-size: 100% 100%;
    padding-left: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}

.cententV {
  height: 15rem;
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;
  margin-top: -0.5rem;
  padding: 1rem;
  box-sizing: border-box;
  text-align: start;

  .inputTitle {
    color: #333;
    font-size: 1rem;
    font-family: "Alibaba PuHuiTi";
    font-style: normal;
    font-weight: 700;
  }

  .inputV {
    height: 2.81rem;
    background: #f6f6f6;
    border-radius: 0.43rem;
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-left: 0.62rem;
    padding-right: 0.62rem;
    font-size: 1rem;
    margin-bottom: 0.63rem;

    .van-field {
      color: #333;
      font-size: 1rem;
      background-color: #00000000;

      input {
        color: #333;
      }
    }
  }
}

.moneyItem {
  width: 4.87rem;
  height: 3.12rem;
  background: #f6f6f6;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.62rem;
  color: #0a51a1;
  font-size: 14px;
  border: 1px solid #f6f6f6;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
}

.moneyItemTo {
  width: 4.87rem;
  height: 3.12rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.62rem;
  color: #0a51a1;
  font-size: 14px;
  background: rgba(10, 81, 161, 0.05);
  border: 1px solid #0a51a1;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
}

/deep/.van-grid-item__content {
  padding: 0px 0px;
}

.cardV {
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  text-align: start;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.62rem;
  color: #333;
  font-size: 1rem;
}

.cardItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.62rem;
  padding-bottom: 0.62rem;
  padding-left: 1rem;
  padding-right: 1rem;

  span {
    width: 90%;
    color: #333;
    font-size: 0.93rem;
    overflow: hidden;
    word-break: break-all;
    text-align: start;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
}

.confirmTv {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.12rem;
  color: #fff;
  height: 2.75rem;
  background: #0a51a1;
  border-radius: 0.5rem;
}

.htmlTv {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: start;
}
</style>
